import React from "react";
//Component Imports
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import SocialContacts from "./components/SocialContacts";
//Global Styles
import AppWrapper from "./components/AppWrapper";
//Theme
import { ThemeProvider } from "styled-components";
import theme from "./content/theme";

function App() {
  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Intro />
        <SocialContacts />
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;

import React from "react";
import styled from "styled-components";

const CenterWrapper = styled.div`
  margin: 0px auto;
  width: 100%;
  max-width: 900px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeyWrapper = styled.h5`
  font-size: 25.5px;
  color: #ef9d10;
  font-weight: normal;

  @media (max-width: 375px) {
    font-size: 20px;
  }
`;

const Name = styled.h1`
  font-size: 70px;
  font-weight: bold;
  color: black;

  & span {
    color: orange;
  }

  @media (max-width: 1000px) {
    font-size: 60px;
  }

  @media (max-width: 768px) {
    font-size: 50px;
  }

  @media (max-width: 638px) {
    font-size: 7.2vw;
  }

  @media (max-width: 495px) {
    font-size: 40px;
  }

  @media (max-width: 375px) {
    font-size: 40px;
    margin: 0.5rem 0;
  }

  @media (max-width: 300px) {
    font-size: 34px;
  }
`;

const AboutDesc = styled.p`
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  padding: 0 0 2.8rem 0;
`;

const ContactButton = styled.a`
  padding: 10px 13px;
  border: 1px solid #ef9d10;
  border-radius: 5px;
  color: orange;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;

  &:hover {
    background-color: #ef9d10;
    color: white;
  }

  @media (max-width: 375px) {
    font-size: 1rem;
  }
`;

const Intro: React.FC = () => {
  return (
    <CenterWrapper className="animate__animated animate__fadeInDown">
      <div style={{ marginLeft: "2rem" }}>
        <HeyWrapper>Hey,</HeyWrapper>
        <Name>
          I'm <span>Bharadwaj.</span>
        </Name>
        <AboutDesc>🕴 💼</AboutDesc>
        <ContactButton href="mailto:bharadwaj.duggaraju@outlook.com">Contact Me</ContactButton>
      </div>
    </CenterWrapper>
  );
};

export default Intro;
